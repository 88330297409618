import Box from "./Box";

import { useViewportSize } from "../core/hooks";

function ViewportHeightBox({ children }) {
  const { innerHeight } = useViewportSize();

  return (
    <Box height={innerHeight} display="flex" alignItems="center">
      {children}
    </Box>
  );
}

export default ViewportHeightBox;
