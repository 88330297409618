import BodyText from "./BodyText";
import BodyTextLarge from "./BodyTextLarge";
import Box from "./Box";
import BulletList from "./BulletList";
import LottieOnEntered from "./LottieOnEntered";

function MobileDiagrams({ items }) {
  return (
    <Box display={[null, null, "none", null]} pt={50} pb={80}>
      {items.map((item, index) => {
        const lastOfType = index === items.length - 1;
        return (
          <Box key={item.heading}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={200}
              mx="auto"
            >
              <LottieOnEntered animData={item.animData} threshold={0} />
            </Box>

            <Box pt={30} pb={lastOfType ? 0 : 30}>
              <BodyTextLarge color="lime">{item.heading}</BodyTextLarge>
              <BodyTextLarge>{item.text}</BodyTextLarge>
              {item.extraHeading && item.list && (
                <>
                  <BodyText pt={10} pb={15}>
                    {item.extraHeading}
                  </BodyText>
                  <BulletList items={item.list} />
                </>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default MobileDiagrams;
