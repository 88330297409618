import Box from "./Box";
import Card from "./Card";
import MediumHeading from "./MediumHeading";
import NarrowCol from "./NarrowCol";

function HeadingAndCards({
  heading,
  cards,
  colsNo,
  narrowHeading,
  numberedCards,
}) {
  const cols = "1fr ".repeat(colsNo);
  return (
    <NarrowCol>
      <Box
        maxWidth={narrowHeading ? [280, 500, 750, null] : ""}
        mx={narrowHeading ? "auto" : ""}
      >
        <MediumHeading>{heading}</MediumHeading>
      </Box>
      <Box
        pt={[60, null, 145, null]}
        display="grid"
        gridTemplateColumns={["1fr", cols, null, null]}
        gridAutoRows="1fr"
        gridColumnGap="3%"
        gridRowGap={30}
      >
        {cards.map((card, index) => {
          const transitionDelay = (index % colsNo) * 150;
          return (
            <Card
              key={card.heading}
              heading={card.heading}
              animData={card.animData}
              transitionDelay={transitionDelay}
              text={card.text}
              list={card.list}
              number={numberedCards ? index + 1 : null}
            />
          );
        })}
      </Box>
    </NarrowCol>
  );
}

export default HeadingAndCards;
